import React from 'react';
import Container from '../../../Shared/Container/Container';
import VerticalsCard from '../Card/VerticalsCard';

const EXPERTISE = [
  {
    title: 'Care Bears: Care Karts',
    description:
      'Create fast access to education through learning applications and the gamification of educational experiences.',
    type: 'Edtech',
    imgName: 'edtech.png',
    link: '/verticals/edtech',
  },
  {
    title: 'Stacks',
    description:
      'We help brands, creating complex financial solutions, build uncomplicated, innovative applications.',
    type: 'Blockchain',
    imgName: 'fintech.png',
    link: '/verticals/fintech',
  },
  {
    title: 'Stick Sports',
    description:
      'Make better healthcare accessible and manageable, through improved digital solutions.',
    type: 'Games',
    imgName: 'games.png',
    link: '/verticals/games',
  },
  {
    title: 'LifePrint',
    description:
      'Create fast access to education through learning applications and the gamification of educational experiences.',
    type: 'IoT',
    imgName: 'iot.png',
    link: '/verticals/iot',
  },
  {
    title: 'EmpowHER',
    description:
      'Make better healthcare accessible and manageable, through improved digital solutions.',
    type: 'Health Tech',
    imgName: 'healthtech.png',
    link: '/verticals/healthtech',
  },
  {
    title: 'Room Builder',
    description:
      'Create fast access to education through learning applications and the gamification of educational experiences.',
    type: 'AR/VR',
    imgName: 'arvr.png',
    link: '/verticals/ar-vr',
  },
];

function IndustryVerticalsSection() {
  return (
    <section className="connect-web__verticals-section">
      <Container>
        <div className="connect-web__verticals-heading">Industries We Serve</div>
        <p className="connect-web__verticals-description">
          We have worked across multiple industries, offering varied solutions to a number of award
          winning companies.
        </p>
        <div className="connect-web__verticals-cards">
          {EXPERTISE.map(({title, description, type, imgName, link}) => (
            <VerticalsCard
              key={title}
              title={title}
              description={description}
              type={type}
              imgName={imgName}
              link={link}
            />
          ))}
        </div>
      </Container>
    </section>
  );
}

export default IndustryVerticalsSection;
