import React from 'react';
import Container from '../../../Shared/Container/Container';
import TechnologiesCard from '../Card/TechnologiesCard';

const TECHNOLOGIES = [
  {
    title: 'Frontend Development',
    logos: [
      {src: 'reactjs.png', title: 'ReactJs'},
      {src: 'angularjs.png', title: 'AngularJs'},
      {src: 'vuejs.png', title: 'VueJs'},
      {src: 'reactivex.png', title: 'ReactiveX'},
      {src: 'jquery.png', title: 'jQuery'},
    ],
  },
  {
    title: 'Backend Development',
    logos: [
      {src: 'django.png', title: 'Django'},
      {src: 'java.png', title: 'Java'},
      {src: 'ruby-on-rails.png', title: 'Ruby on Rails'},
      {src: 'golang.png', title: 'GoLang'},
      {src: 'nodejs.png', title: 'NodeJs'},
    ],
  },
  {
    title: 'Cloud DevOps',
    logos: [
      {src: 'amazon-web-services.png', title: 'AWS'},
      {src: 'google-cloud-platform.png', title: 'Google Cloud '},
      {src: 'firebase.png', title: 'Firebase'},
      {src: 'heroku.png', title: 'Heroku'},
      {src: 'kubernetes.png', title: 'Kubernetes'},
      {src: 'jenkins.png', title: 'Jenkins'},
    ],
  },
];

function OurWebExpertiseSection() {
  return (
    <section className="connect-web__expertise-section">
      <Container>
        <div className="connect-web__expertise-heading">Our Development Expertise</div>
        <p className="connect-web__expertise-description">
          Our team of skilled developers is tailored to deliver leading solutions to your business
        </p>
        <div className="row">
          {TECHNOLOGIES.map(tech => (
            <div key={tech.title} className="col-12 col-md-6" style={{marginTop: '20px'}}>
              <TechnologiesCard key={tech.title} title={tech.title} logos={tech.logos} />
            </div>
          ))}
        </div>
      </Container>
    </section>
  );
}

export default OurWebExpertiseSection;
